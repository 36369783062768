import React, { useState } from "react";
import copyright from "../../src/images/copyright.svg";
import classes from "../../src/cssStyling/footer.module.css";
import instagram from "../../src/images/footer/instagram.svg";
import facebook from "../../src/images/footer/facebook.svg";
import tiktok from "../../src/images/footer/tiktok.svg";
import youtube from "../../src/images/footer/youtube.svg";
import FooterLists from "./FooterLists";
import logo from "../../src/images/header/newlogo.svg";
import { Link } from "react-router-dom";

const footerLinks = [
  {
    title: "روابط سريعة",
    links: [
      { name: "الرئيسية", link: "/" },
      { name: "الأقسام", link: "#coursesDepartments" },
      { name: "الكورسات", link: "/Courses" },
    ],
  },

  {
    title: " عن الموقع",
    links: [
      // { name: "عن الموقع", link: "/" },
      {
        name: "تواصل معنا",
        link: "https://api.whatsapp.com/send/?phone=%2B201064517029&text&type=phone_number&app_absent=0",
      },
    ],
  },

  {
    title: "  الدعم",
    links: [
      {
        name: " الدعم الفنى",
        link: "https://api.whatsapp.com/send/?phone=%2B201064517029&text&type=phone_number&app_absent=0",
      },
    ],
  },

  {
    title: "تابعنا",
    links: [
      {
        name: facebook,
        link: "https://www.facebook.com/AlDiwan.educational.platform?mibextid=wwXIfr&rdid=3pNQaDHIjcaBr5FU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F19wfgtXh8c%2F%3Fmibextid%3DwwXIfr#",
      },
      // { name: tiktok, link: "/" },
      {
        name: instagram,
        link: "https://www.instagram.com/aldiwaneg/?igsh=MWJtY2JscGMxa3d1bg%3D%3D#",
      },
      // { name: youtube, link: "/" },
    ],
  },
];

export default function Footer() {
  const [msg, setMsg] = useState("");

  const handleClick = (e) => {
    ///////// send msg //////////

    setMsg("");
  };

  return (
    <div className={classes.footer}>
      <div className={classes.footerBody}>
        <div className={classes.menu}>
          <div className={classes.allLists}>
            <FooterLists data={footerLinks} />{" "}
          </div>
          <div className={classes.contact}>
            {/* <h1> تواصل معنا بشكل اسرع </h1>
            <div style={{ position: "relative" }}>
              <textarea
                className={classes.message}
                placeholder="أكتب رسالتك الان"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
              />
              <button className={classes.sendBtn} onClick={handleClick}>
                إرسال{" "}
              </button>
            </div> */}
            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img
                  src={logo}
                  style={{
                    width: "11rem",
                    // height: "60px",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>

        {/* ///////////////////////// hr ///////////////////////// */}
        <div className={classes.break}> </div>

        {/* ///////////////////////// copyRight ///////////////////////// */}
        <div className={classes.copyRight}>
          <div className={classes.copyRightContent}>
            <p> الديوان . جميع الحقوق محفوظة. 2025 </p>
            <p>
              <img src={copyright} className={classes.copyrightImage} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import "./style.css";
// import "./omara.css";
// import Logo from "../images/logo.svg";
// import Facebook from "../images/facebook.svg";
// import Instagram from "../images/instagram.svg";
// import Tiktok from "../images/tiktok.svg";
// import Wave from "../images/wave.svg";
// import { Link, withRouter } from "react-router-dom";
// import axios from "axios";

// class Footer extends React.Component {
//   state = {
//     DataLinks: {},
//     message: "",
//   };

//   sendForm = () => {
//     const RegisterData = new FormData();

//     RegisterData.append("message", this.state.message);
//     const user = JSON.parse(localStorage.getItem("user"));
//     if (user) {
//       RegisterData.append("email", "mo.mostafa@nu.edu.eg");
//       axios
//         .post(
//           process.env.REACT_APP_API_BASE_URL + "/api/user/contact_us_create",
//           RegisterData
//         )
//         .then((res) => {
//           window.alert("message sent!");
//           this.setState({ message: "" });
//         })
//         .catch((error) => {
//           window.alert("error");
//           console.error("Error sending the form:", error);
//         });
//     } else {
//       window.location.href = "/signin";
//     }
//   };
//   componentDidMount() {
//     axios
//       .get(process.env.REACT_APP_API_BASE_URL + "/api/user/setting")
//       .then((r) => {
//         let DataLinks = r.data.data;
//         this.setState({ DataLinks });
//       });
//   }

//   // Define a function to handle changes in the input value
//   handleInputChange = (event) => {
//     this.setState({ message: event.target.value });
//   };

//   render() {
//     return (
//       <>
//         <div className="">
//           <div className="main-footer ">
//             <div className="container">
//               <div className="row gap-4 row-mob justify-content-around ">
//                 <div className="col-lg-3 col-md-4 col-sm-12 justify-content-center">
//                   <img src={Logo} className="footer-logo mob-none" />
//                   <h5 className="mob-none">عن الديوان</h5>
//                   <p className="mob-none">
//                     {" "}
//                     منصه بتقدم كورسات فى البيزنس فقط مع نخبه من اساتذه الجامعه
//                   </p>
//                   <div className="social-media-account">
//                     <a
//                       href={this.state.DataLinks?.instagram_url}
//                       target="_blank"
//                     >
//                       <div className="sub-social">
//                         <img src={Instagram} />
//                       </div>
//                     </a>
//                     <a href={this.state.DataLinks?.tiktok_url} target="_blank">
//                       <div className="sub-social">
//                         <img src={Tiktok} />
//                       </div>
//                     </a>
//                     <a
//                       href={this.state.DataLinks?.facebook_url}
//                       target="_blank"
//                     >
//                       <div className="sub-social">
//                         <img src={Facebook} />
//                       </div>
//                     </a>
//                   </div>
//                 </div>
//                 <div className="col-5 d-none d-lg-flex row half-footer pt-5 justify-content-between">
//                   <div className="col-lg-6 col-md-6 col-sm-12 footer-col-1 d-flex  flex-column justify-content-center">
//                     <h6 className="mr-4">أكتشف المزيد</h6>
//                     <ul className="footer-list-1   text-nowrap ">
//                       <li>
//                         <Link to="/singleTeacher">
//                           <p>&nbsp;دوراتنا&nbsp;التدريبية</p>
//                         </Link>
//                       </li>
//                       {/* <li><Link to="/lessons"><p >&nbsp;موادنا التعليمية</p></Link></li> */}
//                       <li>
//                         <Link to="/singleTeacher">
//                           <p>&nbsp;الدورات الأكثر شيوعا</p>
//                         </Link>
//                       </li>
//                       {/* <li><Link to="/singleTeacher"><p >&nbsp;عن المعلم</p></Link></li> */}
//                     </ul>
//                   </div>
//                   <div className="col-lg-6 col-md-4 col-sm-12 footer-col-1 d-flex  flex-column justify-content-center ">
//                     <h6>المزيد</h6>
//                     <ul className="footer-list-2   text-nowrap">
//                       {/* <li>  <Link to="/singleTeacher"><p >عن الديوان</p></Link></li> */}
//                       <li>
//                         {" "}
//                         <a
//                           href={`https://wa.me/${this.state.DataLinks?.whatsapp_number}`}
//                           target="_blank"
//                         >
//                           {" "}
//                           <div>تواصل معنا</div>
//                         </a>
//                       </li>
//                       <li>
//                         {" "}
//                         <Link to={"/terms&conditions"}>
//                           <p>الشروط و الاحكام </p>
//                         </Link>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>

//                 <div className="col-md-3 col-sm-12  footer-col-2 justify-content-center">
//                   <p className="text-center">ابق على اطلاع بأحدث الدورات</p>
//                   <div className="d-flex footer-input-group">
//                     <input
//                       value={this.state.message}
//                       onChange={this.handleInputChange}
//                       className="footer-sumbit"
//                     />
//                     <button
//                       onClick={this.sendForm}
//                       className="new-btn paddingSides "
//                     >
//                       إرسال
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="text-center p-1 lower-footer">
//             <div class="text-light">جميع الحقوق محفوظة لمنصة الديوان 2023 </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(Footer);
