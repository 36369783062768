import React from "react";
import { Link } from "react-router-dom";
import classes from "../../src/cssStyling/footer.module.css";

export default function FooterLists({ data }) {
  return (
    <div className={classes.footerlist}>
      {data.map((item, index) => (
        <div key={index}>
          <h1 className={classes.title}>{item.title}</h1>

          <ul>
            {item.links.map((el, subIndex) => (
              <li key={subIndex}>
                {item.title === "روابط سريعة" ? (
                  el.name === "الأقسام" ? (
                    <Link
                      to={`#`}
                      className={classes.link}
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById("coursesDepartments")
                          ?.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      {el.name}
                    </Link>
                  ) : (
                    <Link to={el.link} className={classes.link}>
                      {el.name}
                    </Link>
                  )
                ) : (
                  <a
                    href={el.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {item.title !== "تابعنا" ? (
                      el.name
                    ) : (
                      <img src={el.name} alt="Social Icon" />
                    )}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
